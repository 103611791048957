import { classnames } from "@vgno/utils";

import { Dateline } from "./Dateline";

import type { Author, Layout } from "vg";

import styles from "./Byline.module.css";

export const Byline = ({
  alignment,
  authors,
  dateline,
  images = true,
  layout = "normal",
  padded = true,
  type,
  className,
}: {
  alignment?: string;
  authors?: Author[];
  dateline?: string;
  images?: boolean;
  layout?: Layout;
  padded?: boolean;
  type?: string;
  className?: string;
}) => {
  if (!authors) {
    return dateline ? <Dateline dateline={dateline} type={type} /> : null;
  }

  authors.sort((a, b) => {
    if (a.imageAsset && !b.imageAsset) {
      return -1;
    }

    if (!a.imageAsset && b.imageAsset) {
      return 1;
    }

    return 0;
  });

  const url = import.meta.env.DEV ? "https://local.vg.no:3443" : "";

  return (
    <section
      className={classnames(
        "label-medium label-primary font-inter",
        styles.byline,
        "layout-component",
        `layout-${layout}`,
        padded && "layout-padded",
        alignment && styles[alignment],
        className && className,
      )}
      data-test-id="byline"
    >
      {authors.some((author) => author.imageAsset) && images && (
        <ul aria-hidden className={styles.images}>
          {authors.map((author, index) => {
            const image = author.imageAsset;

            if (!image) {
              return null;
            }

            const srcSet = image?.urls
              .map(({ url, width }) => `${url} ${width}w`)
              .join(", ");

            return (
              <li key={`image-${author.id}`}>
                <a
                  className={styles.link}
                  href={`${url}/forfatter/${author.slug}`}
                  style={{
                    zIndex: authors.length - index,
                  }}
                  title={author.title}
                >
                  <figure className={styles.figure}>
                    <img
                      alt={`Bilde av ${author.title}`}
                      className={styles.image}
                      // @ts-expect-error - fetchPriority is not valid in React yet
                      fetchpriority="low"
                      loading="lazy"
                      decoding="async"
                      height={image.size.height}
                      itemProp="image"
                      sizes="40px"
                      src={image.urls[0].url}
                      srcSet={srcSet}
                      width={image.size.width}
                    />
                  </figure>
                </a>
              </li>
            );
          })}
        </ul>
      )}
      <ul
        className={classnames(
          styles.names,
          authors.length > 3 && type !== "feature" && styles.truncated,
          type === "feature" && styles.expanded,
        )}
      >
        {authors.map((author, index) => {
          const hidden = index > 2;

          return (
            <li
              className={classnames(
                "font-weight-semi-bold",
                styles.name,
                hidden && type !== "feature" && styles.hidden,
              )}
              key={`author-${author.id}`}
            >
              <a
                className={styles.link}
                href={`${url}/forfatter/${author.slug}`}
              >
                {author.title}
              </a>
            </li>
          );
        })}
        {authors.length > 3 && type !== "feature" && (
          <li
            className={styles.toggle}
            onClick={() =>
              document
                .querySelector(`.${styles.byline}`)
                ?.classList.toggle(styles.expanded)
            }
          >
            <span className={styles.open}>
              og{" "}
              <span
                className={classnames("font-weight-semi-bold", styles.count)}
              >
                {authors.length - 3} til
              </span>
            </span>
            <span className={styles.close}>vis færre</span>
          </li>
        )}
      </ul>
      {dateline ? <Dateline dateline={dateline} type={type} /> : null}
    </section>
  );
};
